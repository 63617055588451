// AOS
AOS.init({
  duration: 1000,
  once: true
});

// SmoothScroll
if ($(window).width() < 960) {
  var scrollOffset = -25;
}
else {
  var scrollOffset = -90;
  $(window).scroll(function(){
    var scrollTransform = (1 + $(window).scrollTop() / 2000);
    var scrollMax = 1.75;
    if (scrollTransform >= scrollMax) {
      scrollTransform = scrollMax;
    }
    $('main .img')
      .css("--transform", scrollTransform)
      .css("opacity", 1.5 - $(window).scrollTop() / 1500)
      .css("filter", "blur(" + ($(window).scrollTop() / 50) + "px)")
      .css("background-position", "center " + (0 + 100 * $(window).scrollTop() / 1500) + "%");
    $('main .main-block')
      .css("opacity", 1 - $(window).scrollTop() / 350);
    $('main .social')
      .css("opacity", 1 - $(window).scrollTop() / 800);
    if($(window).scrollTop() >= 900) {
      $('.navbar .navbar-nav').addClass('navbar-nav-scroll');
    } else {
      $('.navbar .navbar-nav').removeClass('navbar-nav-scroll');
    }
  });
}
var scroll = new SmoothScroll('[data-scroll]', {
  speed: 1500,
  speedAsDuration: true,
  header: '.navbar',
  offset: scrollOffset
});


$('body').scrollspy({ target: '.navbar', offset: 200, })
