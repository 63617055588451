// Mobile View Height Fix
function isMobile() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if(/windows phone/i.test(userAgent)) { // Windows Phone must come first because its UA also contains "Android"
    return true; // Windows Phone must come first because its UA also contains "Android"
  } else if(/Android/i.test(userAgent)) {
    return true; // Android
  } else if(/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
    return true; // iPhone
  } else {
    return false;
  }
}


$.fn.vhFix = function() {
  if(isMobile()) {
    var $this = $(this);
    var $window = $(window).height();
    var $height = (($window - $this.height())*(-1)).toFixed();
    var $fixedHeight = 'calc(100vh - ' + $height + 'px)';
    if($height != 0) {
      $this.attr('style', 'min-height: ' + $fixedHeight + ' !important');
    }
  }
}

$('.vh-fix').vhFix();
